
import HeaderSlider from '@/components/article/Slider.vue'
import Tag from '@/components/Tag.vue'
import TimeChecker from '@/components/TimeChecker.vue'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'WikiHeader',
  components: {
    HeaderSlider,
    Tag,
    TimeChecker,
  },
  props: {
    data: Object,
    pageType: String,
  } as any, // eslint-disable-line
})

<template>
  <div class="header">
    <div class="header-inner">
      <div
        class="header__tags"
        v-if="
          (data.tags && data.tags.length) ||
            (data.primary_tags && data.primary_tags.length) ||
            pageType === 'project' ||
            pageType === 'ads'
        "
      >
        <!-- Is Project -->
        <div class="header__category" v-if="pageType === 'project'">
          <SvgSprite
            class="header__category__icon"
            symbol="icons-woodcraft"
            size="20"
          />
          <span class="h5">{{ data.category }}</span>
        </div>

        <!-- Is Ads -->
        <div class="header__category" v-if="pageType === 'ads'">
          <SvgSprite
            class="header__category__icon"
            symbol="icons-ads"
            size="20"
          />
          <span class="h5">Petite annonce</span>
        </div>

        <!-- Is Event -->
        <template v-if="typeof data.tags === 'string'">
          <div class="header__category" v-if="pageType === 'event'">
            <SvgSprite
              class="header__category__icon"
              :symbol="
                `icons-${data.tags
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')}`
              "
              size="20"
            />
            <span class="h5">{{ data.tags }}</span>
          </div>
        </template>

        <!-- News / Project -->
        <template v-else>
          <Tag
            class="header__tags__tag"
            :data="tag"
            v-for="tag in data.tags"
            :key="`tag-${tag.label}`"
            modifier="alt"
          />
        </template>

        <!-- Activity / Recipe -->
        <Tag
          class="header__tags__tag"
          :data="tag"
          v-for="tag in data.primary_tags"
          :key="`tag-${tag.label}`"
          modifier="alt"
        />
      </div>

      <h1 class="h2 header__title" v-html="data.title"></h1>

      <div class="header__infos">
        <!-- Category if Ads -->
        <div
          class="header__infos__ads"
          v-if="data.category && pageType === 'ads'"
        >
          <div>{{ data.category }}</div>
        </div>

        <div
          class="header__infos__item"
          v-if="data.date && pageType != 'event'"
        >
          <SvgSprite
            class="header__infos__item__icon"
            symbol="ui-calendar"
            size="10"
          />
          <TimeChecker
            class="header__infos__item__label"
            :data="{ date: data.date }"
            type="news"
          />
        </div>

        <!-- Date if Event -->
        <div
          class="header__infos__date"
          v-if="data.date && pageType === 'event'"
        >
          <div class="h3">{{ data.date }}</div>
        </div>

        <div class="header__infos__item" v-if="data.author">
          <SvgSprite
            v-if="pageType === 'project'"
            class="header__infos__item__icon"
            symbol="ui-member"
            size="10"
          />
          <SvgSprite
            v-else
            class="header__infos__item__icon"
            symbol="ui-writer"
            size="10"
          />
          <div class="header__infos__item__label">
            {{ data.author }}
          </div>
        </div>
        <div class="header__infos__item" v-if="data.location">
          <SvgSprite
            class="header__infos__item__icon"
            symbol="ui-location"
            size="18"
          />
          <div class="header__infos__item__label">
            {{ data.location }}
          </div>
        </div>
      </div>
    </div>

    <!-- META -->
    <div class="header__meta" v-if="data.extraInfos">
      <div class="header__meta-inner">
        <!-- ACTIVITIES -->
        <div class="header__meta__item" v-if="data.extraInfos.nbPlayers">
          <SvgSprite
            class="header__meta__item__icon"
            symbol="ui-member"
            size="10"
          />
          <div class="header__meta__item__label">
            {{ data.extraInfos.nbPlayers }}
          </div>
        </div>
        <!-- <div class="header__meta__item" v-if="data.extraInfos.ageRange">
          <SvgSprite
            class="header__meta__item__icon"
            symbol="ui-member"
            size="10"
          />
          <div class="header__meta__item__label">
            {{ data.extraInfos.ageRange }}
          </div>
        </div> -->
        <div class="header__meta__item" v-if="data.extraInfos.duration">
          <SvgSprite
            class="header__meta__item__icon"
            symbol="ui-duration"
            size="10"
          />
          <div class="header__meta__item__label">
            {{ data.extraInfos.duration }}
          </div>
        </div>
        <!-- RECIPE -->
        <div class="header__meta__item" v-if="data.extraInfos.servings">
          <SvgSprite
            class="header__meta__item__icon"
            symbol="ui-member"
            size="10"
          />
          <div class="header__meta__item__label">
            {{ data.extraInfos.servings }}
          </div>
        </div>
        <div class="header__meta__item" v-if="data.extraInfos.prepTime">
          <SvgSprite
            class="header__meta__item__icon"
            symbol="ui-preparation"
            size="10"
          />
          <div class="header__meta__item__label">
            {{ data.extraInfos.prepTime }}
          </div>
        </div>
        <div class="header__meta__item" v-if="data.extraInfos.cookTime">
          <SvgSprite
            class="header__meta__item__icon"
            symbol="ui-cooking"
            size="10"
          />
          <div class="header__meta__item__label">
            {{ data.extraInfos.cookTime }}
          </div>
        </div>
        <div class="header__meta__item price" v-if="data.extraInfos.price">
          <div class="header__meta__item__label">
            {{ data.extraInfos.price }}
          </div>
        </div>
      </div>
    </div>

    <!-- SIMPLE PICTURE -->
    <div class="header__picture" v-if="data.picture && data.picture.src">
      <g-picture
        class="header__picture-inner"
        :content="data.picture"
        :key="data.title"
      />
      <div
        class="picture__description"
        v-if="data.lead || data.extraInfos?.resume"
      >
        <div v-html="data.lead || data.extraInfos.resume"></div>
      </div>
    </div>

    <!-- SLIDER -->
    <div class="header__picture" v-if="data.pictures && data.pictures[0].src">
      <HeaderSlider :data="data.pictures" />

      <div
        class="picture__description"
        v-if="data.lead || data.extraInfos?.resume"
      >
        <div v-html="data.lead || data.extraInfos.resume"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import HeaderSlider from '@/components/article/Slider.vue'
import Tag from '@/components/Tag.vue'
import TimeChecker from '@/components/TimeChecker.vue'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'WikiHeader',
  components: {
    HeaderSlider,
    Tag,
    TimeChecker,
  },
  props: {
    data: Object,
    pageType: String,
  } as any, // eslint-disable-line
})
</script>

<style lang="scss" scoped>
.header {
  padding-bottom: $spacing * 2;

  ::v-deep {
    .slider {
      margin: 0 auto $spacing * 2;
      max-width: none;

      @include mq(xl) {
        margin: 0 auto $spacing * 2;
      }
    }
  }
}

.header-inner {
  margin: 0 $spacing * 1.35;

  @include mq(m) {
    margin: 0;
  }
}

.header__tags {
  display: flex;
  align-items: center;

  ::v-deep {
    [class*='tag--'] {
      margin-bottom: 0;
    }
  }
}

.header__tags__tag {
  margin-right: $spacing / 4;
}

.header__title {
  .branch-horizon &,
  .branch-route & {
    color: white;
  }
}

* + .header__title {
  margin-top: $spacing * 0.85;
  line-height: 1.3em;
  max-width: 80%;

  @include mq(m) {
    // margin-top: $spacing * 1.2;
    max-width: 90%;
  }
}

.header__infos {
  display: flex;
  margin-top: $spacing * 0.85;
  @include mq(m) {
    // margin-top: $spacing * 1.2; TBC
    margin-top: 0.6rem;
  }
}

.header__infos__item {
  display: flex;
  align-items: center;
  margin-right: $spacing;
  font-size: 1.2rem;
  line-height: 1.2em;
}

.header__infos__ads {
  @include label-new;

  margin-right: 1.8rem;
  margin-left: 0;
  background-color: $rock-blue;
}

.header__infos__item__label {
  color: $hippie-blue;

  .branch-horizon &,
  .branch-route & {
    color: white;
  }
}

.header__infos__item__icon {
  width: 1rem;
  height: 1rem;
  margin-right: $spacing / 4;

  .project & {
    width: 1.8rem;
    height: 1.8rem;
  }
}

.header__infos__date {
  .h3 {
    color: $hippie-blue;
  }
}

.header__meta {
  margin-top: $spacing * 1.25;
  padding: $spacing $spacing * 1.35;
  background-color: $rock-blue;
  @include mq(m) {
    padding: $spacing;
  }
}

.header__meta-inner {
  display: flex;
  align-items: center;
}

.header__meta__item {
  display: flex;
  align-items: center;
  margin-right: $spacing;
  color: $regal-blue;
  font-size: 1.2rem;
  line-height: 1.2em;

  &.price {
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 2px;
  }
}

.header__meta__item__icon {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.7rem;
}

.header__meta + .header__picture {
  margin-top: 0;
}

.header__picture {
  margin-top: $spacing * 1.2;
}

.header__picture-inner {
  position: relative;
  background-color: $c-gray-lightest;
}

.picture {
  @include aspect-ratio(720, 360);
}

.picture__description {
  position: relative;
  margin: -6.2rem $spacing * 1.35 0;
  padding: $spacing;
  color: $c-white;
  font-size: 1.6rem;
  line-height: 1.4em;
  font-weight: 600;
  background-color: $tangerine;
  // transform: translateY(-$spacing * 3);

  @include mq(m) {
    margin: -6.2rem $spacing * 2.5 0;
  }
}

.header__category {
  @include fira-bold;

  margin-right: 1.2rem;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.4rem;
  }
}
</style>
